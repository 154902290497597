export const countryData = {
  AD: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  AE: { currency: "AED", taxType: "VAT", languages: ["en"] },
  AF: { currency: "AFN", taxType: "VAT", languages: ["en"] },
  AG: { currency: "XCD", taxType: "VAT", languages: ["en"] },
  AI: { currency: "XCD", taxType: "VAT", languages: ["en"] },
  AL: { currency: "ALL", taxType: "VAT", languages: ["en"] },
  AM: { currency: "AMD", taxType: "VAT", languages: ["en"] },
  AO: { currency: "AOA", taxType: "VAT", languages: ["en"] },
  AR: { currency: "ARS", taxType: "VAT", languages: ["en"] },
  AS: { currency: "USD", taxType: "VAT", languages: ["en"] },
  AT: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  AU: { currency: "AUD", taxType: "ABN", languages: ["en"] },
  AW: { currency: "AWG", taxType: "VAT", languages: ["en"] },
  AX: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  AZ: { currency: "AZN", taxType: "VAT", languages: ["en"] },
  BA: { currency: "BAM", taxType: "VAT", languages: ["en"] },
  BB: { currency: "BBD", taxType: "VAT", languages: ["en"] },
  BD: { currency: "BDT", taxType: "VAT", languages: ["en"] },
  BE: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  BF: { currency: "XOF", taxType: "VAT", languages: ["en"] },
  BG: { currency: "BGN", taxType: "VAT", languages: ["en"] },
  BH: { currency: "BHD", taxType: "VAT", languages: ["en"] },
  BI: { currency: "BIF", taxType: "VAT", languages: ["en"] },
  BJ: { currency: "XOF", taxType: "VAT", languages: ["en"] },
  BL: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  BM: { currency: "BMD", taxType: "VAT", languages: ["en"] },
  BN: { currency: "BND", taxType: "VAT", languages: ["en"] },
  BO: { currency: "BOB", taxType: "VAT", languages: ["en"] },
  BQ: { currency: "USD", taxType: "VAT", languages: ["en"] },
  BR: { currency: "BRL", taxType: "VAT", languages: ["en"] },
  BS: { currency: "BSD", taxType: "VAT", languages: ["en"] },
  BT: { currency: "BTN", taxType: "VAT", languages: ["en"] },
  BV: { currency: "NOK", taxType: "VAT", languages: ["en"] },
  BW: { currency: "BWP", taxType: "VAT", languages: ["en"] },
  BY: { currency: "BYN", taxType: "VAT", languages: ["en"] },
  BZ: { currency: "BZD", taxType: "VAT", languages: ["en"] },
  CA: { currency: "CAD", taxType: "VAT", languages: ["en"] },
  CC: { currency: "AUD", taxType: "VAT", languages: ["en"] },
  CD: { currency: "CDF", taxType: "VAT", languages: ["en"] },
  CF: { currency: "XAF", taxType: "VAT", languages: ["en"] },
  CG: { currency: "CDF", taxType: "VAT", languages: ["en"] },
  CH: { currency: "CHF", taxType: "VAT", languages: ["en"] },
  CI: { currency: "XOF", taxType: "VAT", languages: ["en"] },
  CK: { currency: "NZD", taxType: "VAT", languages: ["en"] },
  CL: { currency: "CLF", taxType: "VAT", languages: ["en"] },
  CM: { currency: "XAF", taxType: "VAT", languages: ["en"] },
  CN: { currency: "CNY", taxType: "VAT", languages: ["en"] },
  CO: { currency: "COP", taxType: "VAT", languages: ["en"] },
  CR: { currency: "CRC", taxType: "VAT", languages: ["en"] },
  CU: { currency: "CUC", taxType: "VAT", languages: ["en"] },
  CV: { currency: "CVE", taxType: "VAT", languages: ["en"] },
  CW: { currency: "ANG", taxType: "VAT", languages: ["en"] },
  CX: { currency: "AUD", taxType: "VAT", languages: ["en"] },
  CY: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  CZ: { currency: "CZK", taxType: "VAT", languages: ["en"] },
  DE: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  DJ: { currency: "DJF", taxType: "VAT", languages: ["en"] },
  DK: { currency: "DKK", taxType: "VAT", languages: ["en"] },
  DM: { currency: "DOP", taxType: "VAT", languages: ["en"] },
  DO: { currency: "DOP", taxType: "VAT", languages: ["en"] },
  DZ: { currency: "DZD", taxType: "VAT", languages: ["en"] },
  EC: { currency: "USD", taxType: "VAT", languages: ["en"] },
  EE: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  EG: { currency: "EGP", taxType: "VAT", languages: ["en"] },
  EH: { currency: "MAD", taxType: "VAT", languages: ["en"] },
  ER: { currency: "ERN", taxType: "VAT", languages: ["en"] },
  ES: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  ET: { currency: "ETB", taxType: "VAT", languages: ["en"] },
  FI: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  FJ: { currency: "FJD", taxType: "VAT", languages: ["en"] },
  FM: { currency: "USD", taxType: "VAT", languages: ["en"] },
  FO: { currency: "DKK", taxType: "VAT", languages: ["en"] },
  FR: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  GA: { currency: "XAF", taxType: "VAT", languages: ["en"] },
  GB: { currency: "GBP", taxType: "VAT", languages: ["en"] },
  GD: { currency: "XCD", taxType: "VAT", languages: ["en"] },
  GE: { currency: "GEL", taxType: "VAT", languages: ["en"] },
  GF: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  GG: { currency: "GBP", taxType: "VAT", languages: ["en"] },
  GH: { currency: "GHS", taxType: "VAT", languages: ["en"] },
  GI: { currency: "GIP", taxType: "VAT", languages: ["en"] },
  GL: { currency: "DKK", taxType: "VAT", languages: ["en"] },
  GM: { currency: "GMD", taxType: "VAT", languages: ["en"] },
  GN: { currency: "GNF", taxType: "VAT", languages: ["en"] },
  GP: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  GQ: { currency: "XAF", taxType: "VAT", languages: ["en"] },
  GR: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  GT: { currency: "GTQ", taxType: "VAT", languages: ["en"] },
  GU: { currency: "USD", taxType: "VAT", languages: ["en"] },
  GW: { currency: "XOF", taxType: "VAT", languages: ["en"] },
  GY: { currency: "GYD", taxType: "VAT", languages: ["en"] },
  HK: { currency: "HKD", taxType: "VAT", languages: ["en"] },
  HN: { currency: "HNL", taxType: "VAT", languages: ["en"] },
  HR: { currency: "HRK", taxType: "VAT", languages: ["en"] },
  HT: { currency: "HTG", taxType: "VAT", languages: ["en"] },
  HU: { currency: "HUF", taxType: "VAT", languages: ["en"] },
  ID: { currency: "IDR", taxType: "VAT", languages: ["en"] },
  IE: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  IL: { currency: "ILS", taxType: "VAT", languages: ["en"] },
  IM: { currency: "GBP", taxType: "VAT", languages: ["en"] },
  IN: { currency: "INR", taxType: "VAT", languages: ["en"] },
  IO: { currency: "USD", taxType: "VAT", languages: ["en"] },
  IQ: { currency: "IQD", taxType: "VAT", languages: ["en"] },
  IR: { currency: "IRR", taxType: "VAT", languages: ["en"] },
  IS: { currency: "ISK", taxType: "VAT", languages: ["en"] },
  IT: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  JE: { currency: "GBP", taxType: "VAT", languages: ["en"] },
  JM: { currency: "JMD", taxType: "VAT", languages: ["en"] },
  JO: { currency: "JOD", taxType: "VAT", languages: ["en"] },
  JP: { currency: "JPY", taxType: "VAT", languages: ["en"] },
  KE: { currency: "KES", taxType: "VAT", languages: ["en"] },
  KG: { currency: "KGS", taxType: "VAT", languages: ["en"] },
  KH: { currency: "KHR", taxType: "VAT", languages: ["en"] },
  KI: { currency: "AUD", taxType: "VAT", languages: ["en"] },
  KM: { currency: "KMF", taxType: "VAT", languages: ["en"] },
  KN: { currency: "XCD", taxType: "VAT", languages: ["en"] },
  KP: { currency: "KPW", taxType: "VAT", languages: ["en"] },
  KR: { currency: "KRW", taxType: "VAT", languages: ["en"] },
  KW: { currency: "KWD", taxType: "VAT", languages: ["en"] },
  KY: { currency: "KYD", taxType: "VAT", languages: ["en"] },
  KZ: { currency: "KZT", taxType: "VAT", languages: ["en"] },
  LB: { currency: "LBP", taxType: "VAT", languages: ["en"] },
  LC: { currency: "XCD", taxType: "VAT", languages: ["en"] },
  LI: { currency: "CHF", taxType: "VAT", languages: ["en"] },
  LK: { currency: "LKR", taxType: "VAT", languages: ["en"] },
  LR: { currency: "LRD", taxType: "VAT", languages: ["en"] },
  LS: { currency: "LSL", taxType: "VAT", languages: ["en"] },
  LT: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  LU: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  LV: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  LY: { currency: "LYD", taxType: "VAT", languages: ["en"] },
  MA: { currency: "MAD", taxType: "VAT", languages: ["en"] },
  MC: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  MD: { currency: "MDL", taxType: "VAT", languages: ["en"] },
  ME: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  MF: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  MG: { currency: "MGA", taxType: "VAT", languages: ["en"] },
  MH: { currency: "USD", taxType: "VAT", languages: ["en"] },
  ML: { currency: "XOF", taxType: "VAT", languages: ["en"] },
  MM: { currency: "MMK", taxType: "VAT", languages: ["en"] },
  MN: { currency: "MNT", taxType: "VAT", languages: ["en"] },
  MO: { currency: "MOP", taxType: "VAT", languages: ["en"] },
  MP: { currency: "USD", taxType: "VAT", languages: ["en"] },
  MQ: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  MR: { currency: "MRU", taxType: "VAT", languages: ["en"] },
  MS: { currency: "XCD", taxType: "VAT", languages: ["en"] },
  MT: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  MU: { currency: "MUR", taxType: "VAT", languages: ["en"] },
  MV: { currency: "MVR", taxType: "VAT", languages: ["en"] },
  MW: { currency: "MWK", taxType: "VAT", languages: ["en"] },
  MX: { currency: "MXN", taxType: "VAT", languages: ["en"] },
  MY: { currency: "MYR", taxType: "VAT", languages: ["en"] },
  MZ: { currency: "MZN", taxType: "VAT", languages: ["en"] },
  NA: { currency: "NAD", taxType: "VAT", languages: ["en"] },
  NC: { currency: "XPF", taxType: "VAT", languages: ["en"] },
  NE: { currency: "NGN", taxType: "VAT", languages: ["en"] },
  NF: { currency: "AUD", taxType: "VAT", languages: ["en"] },
  NG: { currency: "NGN", taxType: "VAT", languages: ["en"] },
  NI: { currency: "NIO", taxType: "VAT", languages: ["en"] },
  NL: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  NO: { currency: "NOK", taxType: "VAT", languages: ["en"] },
  NP: { currency: "NPR", taxType: "VAT", languages: ["en"] },
  NR: { currency: "AUD", taxType: "VAT", languages: ["en"] },
  NU: { currency: "NZD", taxType: "VAT", languages: ["en"] },
  NZ: { currency: "NZD", taxType: "VAT", languages: ["en"] },
  OM: { currency: "OMR", taxType: "VAT", languages: ["en"] },
  PA: { currency: "PAB", taxType: "VAT", languages: ["en"] },
  PE: { currency: "PEN", taxType: "VAT", languages: ["en"] },
  PF: { currency: "XPF", taxType: "VAT", languages: ["en"] },
  PG: { currency: "PGK", taxType: "VAT", languages: ["en"] },
  PH: { currency: "PHP", taxType: "VAT", languages: ["en"] },
  PK: { currency: "PKR", taxType: "VAT", languages: ["en"] },
  PL: { currency: "PLN", taxType: "VAT", languages: ["en"] },
  PM: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  PN: { currency: "NZD", taxType: "VAT", languages: ["en"] },
  PR: { currency: "USD", taxType: "VAT", languages: ["en"] },
  PT: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  PW: { currency: "USD", taxType: "VAT", languages: ["en"] },
  PY: { currency: "PYG", taxType: "VAT", languages: ["en"] },
  QA: { currency: "QAR", taxType: "VAT", languages: ["en"] },
  RE: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  RO: { currency: "RON", taxType: "VAT", languages: ["en"] },
  RS: { currency: "RSD", taxType: "VAT", languages: ["en"] },
  RU: { currency: "RUB", taxType: "VAT", languages: ["en"] },
  RW: { currency: "RWF", taxType: "VAT", languages: ["en"] },
  SA: { currency: "SAR", taxType: "VAT", languages: ["en"] },
  SB: { currency: "SBD", taxType: "VAT", languages: ["en"] },
  SC: { currency: "SCR", taxType: "VAT", languages: ["en"] },
  SD: { currency: "SDG", taxType: "VAT", languages: ["en"] },
  SE: { currency: "SEK", taxType: "VAT", languages: ["en"] },
  SG: { currency: "SGD", taxType: "VAT", languages: ["en"] },
  SH: { currency: "SHP", taxType: "VAT", languages: ["en"] },
  SI: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  SJ: { currency: "NOK", taxType: "VAT", languages: ["en"] },
  SK: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  SL: { currency: "SLL", taxType: "VAT", languages: ["en"] },
  SM: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  SN: { currency: "XOF", taxType: "VAT", languages: ["en"] },
  SO: { currency: "SOS", taxType: "VAT", languages: ["en"] },
  SR: { currency: "SRD", taxType: "VAT", languages: ["en"] },
  SS: { currency: "SSP", taxType: "VAT", languages: ["en"] },
  ST: { currency: "STN", taxType: "VAT", languages: ["en"] },
  SV: { currency: "SVC", taxType: "VAT", languages: ["en"] },
  SX: { currency: "ANG", taxType: "VAT", languages: ["en"] },
  SY: { currency: "SYP", taxType: "VAT", languages: ["en"] },
  SZ: { currency: "SZL", taxType: "VAT", languages: ["en"] },
  TC: { currency: "USD", taxType: "VAT", languages: ["en"] },
  TD: { currency: "XAF", taxType: "VAT", languages: ["en"] },
  TF: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  TG: { currency: "XOF", taxType: "VAT", languages: ["en"] },
  TH: { currency: "THB", taxType: "VAT", languages: ["en"] },
  TJ: { currency: "TJS", taxType: "VAT", languages: ["en"] },
  TK: { currency: "NZD", taxType: "VAT", languages: ["en"] },
  TL: { currency: "USD", taxType: "VAT", languages: ["en"] },
  TM: { currency: "TMT", taxType: "VAT", languages: ["en"] },
  TN: { currency: "TND", taxType: "VAT", languages: ["en"] },
  TO: { currency: "TOP", taxType: "VAT", languages: ["en"] },
  TR: { currency: "TRY", taxType: "VAT", languages: ["en"] },
  TT: { currency: "TTD", taxType: "VAT", languages: ["en"] },
  TV: { currency: "AUD", taxType: "VAT", languages: ["en"] },
  TW: { currency: "TWD", taxType: "VAT", languages: ["en"] },
  TZ: { currency: "TZS", taxType: "VAT", languages: ["en"] },
  UA: { currency: "UAH", taxType: "VAT", languages: ["en"] },
  UG: { currency: "UGX", taxType: "VAT", languages: ["en"] },
  UM: { currency: "USD", taxType: "VAT", languages: ["en"] },
  US: { currency: "USD", taxType: "VAT", languages: ["en"] },
  UY: { currency: "UYI", taxType: "VAT", languages: ["en"] },
  UZ: { currency: "UZS", taxType: "VAT", languages: ["en"] },
  VA: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  VC: { currency: "XCD", taxType: "VAT", languages: ["en"] },
  VE: { currency: "VES", taxType: "VAT", languages: ["en"] },
  VG: { currency: "USD", taxType: "VAT", languages: ["en"] },
  VI: { currency: "USD", taxType: "VAT", languages: ["en"] },
  VN: { currency: "VND", taxType: "VAT", languages: ["en"] },
  VU: { currency: "VUV", taxType: "VAT", languages: ["en"] },
  WF: { currency: "XPF", taxType: "VAT", languages: ["en"] },
  WS: { currency: "USD", taxType: "VAT", languages: ["en"] },
  YE: { currency: "YER", taxType: "VAT", languages: ["en"] },
  YT: { currency: "EUR", taxType: "VAT", languages: ["en"] },
  ZA: { currency: "ZAR", taxType: "VAT", languages: ["en"] },
  ZM: { currency: "ZMW", taxType: "VAT", languages: ["en"] },
  ZW: { currency: "ZWL", taxType: "VAT", languages: ["en"] },
  HM: { currency: "AUD", taxType: "VAT", languages: ["en"] },
  FK: { currency: "FKP", taxType: "VAT", languages: ["en"] },
  GS: { currency: "GEL", taxType: "VAT", languages: ["en"] },
  LA: { currency: "LAK", taxType: "VAT", languages: ["en"] },
  MK: { currency: "MKD", taxType: "VAT", languages: ["en"] },
  AQ: { currency: "USD", taxType: "VAT", languages: ["en"] },
  PS: { currency: "ILS", taxType: "VAT", languages: ["en"] },
} as any;

export const countryArr = [
  { value: "AF", label: "Afghanistan", region: "EMEA" },
  { value: "AX", label: "Åland Islands", region: "EMEA" },
  { value: "AL", label: "Albania", region: "EMEA" },
  { value: "DZ", label: "Algeria", region: "EMEA" },
  { value: "AS", label: "American Samoa", region: "APAC" },
  { value: "AD", label: "Andorra", region: "EMEA" },
  { value: "AO", label: "Angola", region: "EMEA" },
  { value: "AI", label: "Anguilla", region: "NA" },
  { value: "AQ", label: "Antarctica", region: "NA" },
  { value: "AG", label: "Antigua and Barbuda", region: "NA" },
  { value: "AR", label: "Argentina", region: "SA" },
  { value: "AM", label: "Armenia", region: "EMEA" },
  { value: "AW", label: "Aruba", region: "NA" },
  { value: "AU", label: "Australia", region: "APAC" },
  { value: "AT", label: "Austria", region: "EMEA" },
  { value: "AZ", label: "Azerbaijan", region: "EMEA" },
  { value: "BS", label: "Bahamas", region: "NA" },
  { value: "BH", label: "Bahrain", region: "EMEA" },
  { value: "BD", label: "Bangladesh", region: "APAC" },
  { value: "BB", label: "Barbados", region: "NA" },
  { value: "BY", label: "Belarus", region: "EMEA" },
  { value: "BE", label: "Belgium", region: "EMEA" },
  { value: "BZ", label: "Belize", region: "NA" },
  { value: "BJ", label: "Benin", region: "EMEA" },
  { value: "BM", label: "Bermuda", region: "NA" },
  { value: "BT", label: "Bhutan", region: "APAC" },
  { value: "BO", label: "Bolivia, Plurinational State of", region: "SA" },
  { value: "BQ", label: "Bonaire, Sint Eustatius and Saba", region: "NA" },
  { value: "BA", label: "Bosnia and Herzegovina", region: "EMEA" },
  { value: "BW", label: "Botswana", region: "EMEA" },
  { value: "BV", label: "Bouvet Island", region: "" },
  { value: "BR", label: "Brazil", region: "SA" },
  { value: "IO", label: "British Indian Ocean Territory", region: "" },
  { value: "BN", label: "Brunei Darussalam", region: "APAC" },
  { value: "BG", label: "Bulgaria", region: "EMEA" },
  { value: "BF", label: "Burkina Faso", region: "EMEA" },
  { value: "BI", label: "Burundi", region: "EMEA" },
  { value: "KH", label: "Cambodia", region: "APAC" },
  { value: "CM", label: "Cameroon", region: "EMEA" },
  { value: "CA", label: "Canada", region: "NA" },
  { value: "CV", label: "Cape Verde", region: "EMEA" },
  { value: "KY", label: "Cayman Islands", region: "NA" },
  { value: "CF", label: "Central African Republic", region: "EMEA" },
  { value: "TD", label: "Chad", region: "EMEA" },
  { value: "CL", label: "Chile", region: "SA" },
  { value: "CN", label: "China", region: "APAC" },
  { value: "CX", label: "Christmas Island", region: "APAC" },
  { value: "CC", label: "Cocos (Keeling) Islands", region: "APAC" },
  { value: "CO", label: "Colombia", region: "SA" },
  { value: "KM", label: "Comoros", region: "EMEA" },
  { value: "CG", label: "Congo", region: "EMEA" },
  {
    value: "CD",
    label: "Congo, the Democratic Republic of the",
    region: "EMEA",
  },
  { value: "CK", label: "Cook Islands", region: "APAC" },
  { value: "CR", label: "Costa Rica", region: "NA" },
  { value: "CI", label: "Côte d'Ivoire", region: "EMEA" },
  { value: "HR", label: "Croatia", region: "EMEA" },
  { value: "CU", label: "Cuba", region: "NA" },
  { value: "CW", label: "Curaçao", region: "NA" },
  { value: "CY", label: "Cyprus", region: "EMEA" },
  { value: "CZ", label: "Czech Republic", region: "EMEA" },
  { value: "DK", label: "Denmark", region: "EMEA" },
  { value: "DJ", label: "Djibouti", region: "EMEA" },
  { value: "DM", label: "Dominica", region: "NA" },
  { value: "DO", label: "Dominican Republic", region: "NA" },
  { value: "EC", label: "Ecuador", region: "SA" },
  { value: "EG", label: "Egypt", region: "EMEA" },
  { value: "SV", label: "El Salvador", region: "NA" },
  { value: "GQ", label: "Equatorial Guinea", region: "EMEA" },
  { value: "ER", label: "Eritrea", region: "EMEA" },
  { value: "EE", label: "Estonia", region: "EMEA" },
  { value: "ET", label: "Ethiopia", region: "EMEA" },
  { value: "FK", label: "Falkland Islands (Malvinas)", region: "SA" },
  { value: "FO", label: "Faroe Islands", region: "EMEA" },
  { value: "FJ", label: "Fiji", region: "APAC" },
  { value: "FI", label: "Finland", region: "EMEA" },
  { value: "FR", label: "France", region: "EMEA" },
  { value: "GF", label: "French Guiana", region: "SA" },
  { value: "PF", label: "French Polynesia", region: "APAC" },
  { value: "TF", label: "French Southern Territories", region: "APAC" },
  { value: "GA", label: "Gabon", region: "EMEA" },
  { value: "GM", label: "Gambia", region: "EMEA" },
  { value: "GE", label: "Georgia", region: "EMEA" },
  { value: "DE", label: "Germany", region: "EMEA" },
  { value: "GH", label: "Ghana", region: "EMEA" },
  { value: "GI", label: "Gibraltar", region: "EMEA" },
  { value: "GR", label: "Greece", region: "EMEA" },
  { value: "GL", label: "Greenland", region: "EMEA" },
  { value: "GD", label: "Grenada", region: "NA" },
  { value: "GP", label: "Guadeloupe", region: "NA" },
  { value: "GU", label: "Guam", region: "APAC" },
  { value: "GT", label: "Guatemala", region: "NA" },
  { value: "GG", label: "Guernsey", region: "EMEA" },
  { value: "GN", label: "Guinea", region: "EMEA" },
  { value: "GW", label: "Guinea-Bissau", region: "EMEA" },
  { value: "GY", label: "Guyana", region: "SA" },
  { value: "HT", label: "Haiti", region: "NA" },
  { value: "HM", label: "Heard Island and McDonald Islands", region: "APAC" },
  { value: "VA", label: "Holy See (Vatican City State)", region: "" },
  { value: "HN", label: "Honduras", region: "NA" },
  { value: "HK", label: "Hong Kong", region: "APAC" },
  { value: "HU", label: "Hungary", region: "EMEA" },
  { value: "IS", label: "Iceland", region: "EMEA" },
  { value: "IN", label: "India", region: "APAC" },
  { value: "ID", label: "Indonesia", region: "APAC" },
  { value: "IR", label: "Iran, Islamic Republic of", region: "EMEA" },
  { value: "IQ", label: "Iraq", region: "EMEA" },
  { value: "IE", label: "Ireland", region: "EMEA" },
  { value: "IM", label: "Isle of Man", region: "EMEA" },
  { value: "IL", label: "Israel", region: "EMEA" },
  { value: "IT", label: "Italy", region: "EMEA" },
  { value: "JM", label: "Jamaica", region: "NA" },
  { value: "JP", label: "Japan", region: "APAC" },
  { value: "JE", label: "Jersey", region: "EMEA" },
  { value: "JO", label: "Jordan", region: "EMEA" },
  { value: "KZ", label: "Kazakhstan", region: "EMEA" },
  { value: "KE", label: "Kenya", region: "EMEA" },
  { value: "KI", label: "Kiribati", region: "APAC" },
  {
    value: "KP",
    label: "Korea, Democratic People's Republic of",
    region: "APAC",
  },
  { value: "KR", label: "Korea, Republic of", region: "APAC" },
  { value: "KW", label: "Kuwait", region: "EMEA" },
  { value: "KG", label: "Kyrgyzstan", region: "EMEA" },
  { value: "LA", label: "Lao People's Democratic Republic", region: "APAC" },
  { value: "LV", label: "Latvia", region: "EMEA" },
  { value: "LB", label: "Lebanon", region: "EMEA" },
  { value: "LS", label: "Lesotho", region: "EMEA" },
  { value: "LR", label: "Liberia", region: "EMEA" },
  { value: "LY", label: "Libya", region: "EMEA" },
  { value: "LI", label: "Liechtenstein", region: "EMEA" },
  { value: "LT", label: "Lithuania", region: "EMEA" },
  { value: "LU", label: "Luxembourg", region: "EMEA" },
  { value: "MO", label: "Macao", region: "APAC" },
  {
    value: "MK",
    label: "Macedonia, the former Yugoslav Republic of",
    region: "EMEA",
  },
  { value: "MG", label: "Madagascar", region: "EMEA" },
  { value: "MW", label: "Malawi", region: "EMEA" },
  { value: "MY", label: "Malaysia", region: "APAC" },
  { value: "MV", label: "Maldives", region: "EMEA" },
  { value: "ML", label: "Mali", region: "APAC" },
  { value: "MT", label: "Malta", region: "EMEA" },
  { value: "MH", label: "Marshall Islands", region: "APAC" },
  { value: "MQ", label: "Martinique", region: "NA" },
  { value: "MR", label: "Mauritania", region: "EMEA" },
  { value: "MU", label: "Mauritius", region: "EMEA" },
  { value: "YT", label: "Mayotte", region: "EMEA" },
  { value: "MX", label: "Mexico", region: "NA" },
  { value: "FM", label: "Micronesia, Federated States of", region: "APAC" },
  { value: "MD", label: "Moldova, Republic of", region: "EMEA" },
  { value: "MC", label: "Monaco", region: "EMEA" },
  { value: "MN", label: "Mongolia", region: "APAC" },
  { value: "ME", label: "Montenegro", region: "EMEA" },
  { value: "MS", label: "Montserrat", region: "NA" },
  { value: "MA", label: "Morocco", region: "EMEA" },
  { value: "MZ", label: "Mozambique", region: "EMEA" },
  { value: "MM", label: "Myanmar", region: "APAC" },
  { value: "NA", label: "Namibia", region: "EMEA" },
  { value: "NR", label: "Nauru", region: "APAC" },
  { value: "NP", label: "Nepal", region: "APAC" },
  { value: "NL", label: "Netherlands", region: "EMEA" },
  { value: "NC", label: "New Caledonia", region: "APAC" },
  { value: "NZ", label: "New Zealand", region: "APAC" },
  { value: "NI", label: "Nicaragua", region: "NA" },
  { value: "NE", label: "Niger", region: "EMEA" },
  { value: "NG", label: "Nigeria", region: "EMEA" },
  { value: "NU", label: "Niue", region: "APAC" },
  { value: "NF", label: "Norfolk Island", region: "APAC" },
  { value: "MP", label: "Northern Mariana Islands", region: "APAC" },
  { value: "NO", label: "Norway", region: "EMEA" },
  { value: "OM", label: "Oman", region: "EMEA" },
  { value: "PK", label: "Pakistan", region: "APAC" },
  { value: "PW", label: "Palau", region: "APAC" },
  { value: "PS", label: "Palestinian Territory, Occupied", region: "EMEA" },
  { value: "PA", label: "Panama", region: "NA" },
  { value: "PG", label: "Papua New Guinea", region: "APAC" },
  { value: "PY", label: "Paraguay", region: "SA" },
  { value: "PE", label: "Peru", region: "SA" },
  { value: "PH", label: "Philippines", region: "APAC" },
  { value: "PN", label: "Pitcairn", region: "APAC" },
  { value: "PL", label: "Poland", region: "EMEA" },
  { value: "PT", label: "Portugal", region: "EMEA" },
  { value: "PR", label: "Puerto Rico", region: "NA" },
  { value: "QA", label: "Qatar", region: "EMEA" },
  { value: "RE", label: "Réunion", region: "EMEA" },
  { value: "RO", label: "Romania", region: "EMEA" },
  { value: "RU", label: "Russian Federation", region: "EMEA" },
  { value: "RW", label: "Rwanda", region: "EMEA" },
  { value: "BL", label: "Saint Barthélemy", region: "NA" },
  {
    value: "SH",
    label: "Saint Helena, Ascension and Tristan da Cunha",
    region: "EMEA",
  },
  { value: "KN", label: "Saint Kitts and Nevis", region: "NA" },
  { value: "LC", label: "Saint Lucia", region: "NA" },
  { value: "MF", label: "Saint Martin (French part)", region: "NA" },
  { value: "PM", label: "Saint Pierre and Miquelon", region: "NA" },
  { value: "VC", label: "Saint Vincent and the Grenadines", region: "NA" },
  { value: "WS", label: "Samoa", region: "APAC" },
  { value: "SM", label: "San Marino", region: "EMEA" },
  { value: "ST", label: "Sao Tome and Principe", region: "NA" },
  { value: "SA", label: "Saudi Arabia", region: "EMEA" },
  { value: "SN", label: "Senegal", region: "EMEA" },
  { value: "RS", label: "Serbia", region: "EMEA" },
  { value: "SC", label: "Seychelles", region: "EMEA" },
  { value: "SL", label: "Sierra Leone", region: "EMEA" },
  { value: "SG", label: "Singapore", region: "APAC" },
  { value: "SX", label: "Sint Maarten (Dutch part)", region: "NA" },
  { value: "SK", label: "Slovakia", region: "EMEA" },
  { value: "SI", label: "Slovenia", region: "EMEA" },
  { value: "SB", label: "Solomon Islands", region: "APAC" },
  { value: "SO", label: "Somalia", region: "EMEA" },
  { value: "ZA", label: "South Africa", region: "EMEA" },
  {
    value: "GS",
    label: "South Georgia and the South Sandwich Islands",
    region: "SA",
  },
  { value: "SS", label: "South Sudan", region: "EMEA" },
  { value: "ES", label: "Spain", region: "EMEA" },
  { value: "LK", label: "Sri Lanka", region: "APAC" },
  { value: "SD", label: "Sudan", region: "EMEA" },
  { value: "SR", label: "Suriname", region: "SA" },
  { value: "SJ", label: "Svalbard and Jan Mayen", region: "EMEA" },
  { value: "SZ", label: "Swaziland", region: "EMEA" },
  { value: "SE", label: "Sweden", region: "EMEA" },
  { value: "CH", label: "Switzerland", region: "EMEA" },
  { value: "SY", label: "Syrian Arab Republic", region: "EMEA" },
  { value: "TW", label: "Taiwan, Province of China", region: "APAC" },
  { value: "TJ", label: "Tajikistan", region: "EMEA" },
  { value: "TZ", label: "Tanzania, United Republic of", region: "EMEA" },
  { value: "TH", label: "Thailand", region: "APAC" },
  { value: "TL", label: "Timor-Leste", region: "APAC" },
  { value: "TG", label: "Togo", region: "EMEA" },
  { value: "TK", label: "Tokelau", region: "APAC" },
  { value: "TO", label: "Tonga", region: "APAC" },
  { value: "TT", label: "Trinidad and Tobago", region: "NA" },
  { value: "TN", label: "Tunisia", region: "EMEA" },
  { value: "TR", label: "Turkey", region: "EMEA" },
  { value: "TM", label: "Turkmenistan", region: "EMEA" },
  { value: "TC", label: "Turks and Caicos Islands", region: "NA" },
  { value: "TV", label: "Tuvalu", region: "APAC" },
  { value: "UG", label: "Uganda", region: "EMEA" },
  { value: "UA", label: "Ukraine", region: "EMEA" },
  { value: "AE", label: "United Arab Emirates", region: "EMEA" },
  { value: "GB", label: "United Kingdom", region: "EMEA" },
  { value: "US", label: "United States", region: "NA" },
  { value: "UM", label: "United States Minor Outlying Islands", region: "NA" },
  { value: "UY", label: "Uruguay", region: "SA" },
  { value: "UZ", label: "Uzbekistan", region: "EMEA" },
  { value: "VU", label: "Vanuatu", region: "APAC" },
  { value: "VE", label: "Venezuela, Bolivarian Republic of", region: "SA" },
  { value: "VN", label: "Viet Nam", region: "APAC" },
  { value: "VG", label: "Virgin Islands, British", region: "NA" },
  { value: "VI", label: "Virgin Islands, U.S.", region: "NA" },
  { value: "WF", label: "Wallis and Futuna", region: "APAC" },
  { value: "EH", label: "Western Sahara", region: "EMEA" },
  { value: "YE", label: "Yemen", region: "EMEA" },
  { value: "ZM", label: "Zambia", region: "EMEA" },
  { value: "ZW", label: "Zimbabwe", region: "EMEA" },
] as any;
