import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  isMetric,
  setMetric,
  setImperial,
} from '../../features/metrics/metricsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { iplocate, geolocate } from '../../lib/geoapify';
import styles from '../../styles/components/molecules/Metrics.module.scss';

interface MetricsProps {
  dark?: boolean;
  onClick?: (e: boolean) => void;
  className?: string;
}

export default function Metrics(props: MetricsProps) {
  const { onClick, dark, className } = props;
  const [detected, setDetected] = useState(false);
  const dispatch = useAppDispatch();
  const metricState = useAppSelector(isMetric);

  const classes = clsx(
    styles.metrics,
    { [styles.dark]: dark },
    { [styles.metric]: metricState },
    className
  );

  const onChange = (e: any) => {
    if (metricState) {
      localStorage.setItem('vs-metrics', 'imperial');
      dispatch(setImperial());
    } else {
      localStorage.setItem('vs-metrics', 'metric');
      dispatch(setMetric());
    }

    if (!!onClick) {
      onClick(!metricState);
    }
  };

  useEffect(() => {
    if (!detected) {
      setDetected(true);
      const defaultImperials = ['US', 'MM', 'LR']; // USA, Myanmar, Liberia
      const prev = localStorage.getItem('vs-metrics');
      if (prev) {
        if (prev === 'imperial') {
          localStorage.setItem('vs-metrics', 'imperial');
          dispatch(setImperial());
        }
      } else {
        iplocate().then((response) => {
          const { iso_code } = response;
          if (defaultImperials.indexOf(iso_code) > -1) {
            localStorage.setItem('vs-metrics', 'imperial');
            dispatch(setImperial());
          }
        });
        // const getLocation = async () => {
        //   const location = await geolocate();
        //   if (defaultImperials.indexOf(location.country.iso_code) > -1) {
        //     localStorage.setItem('vs-metrics', 'imperial');
        //     dispatch(setImperial());
        //   }
        // };
        // getLocation();
      }
    }
  }, [detected, dispatch]);

  return (
    <div className={classes} onClick={onChange}>
      <small>
        <b>MT</b>
      </small>{' '}
      |{' '}
      <small>
        <b>FT</b>
      </small>
    </div>
  );
}
