import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import styles from '../../styles/components/atoms/Logo.module.scss';

interface LogoProps {
  medium?: boolean;
  large?: boolean;
  full?: boolean;
  dark?: boolean;
  typo?: boolean;
  center?: boolean;
  noLink?: boolean;
}

export default function Logo({
  medium,
  large,
  full,
  dark,
  typo,
  center,
  noLink,
}: LogoProps) {
  const { logo, path } = styles;
  const logoSize = full
    ? 'full'
    : large
    ? 'large'
    : medium
    ? 'medium'
    : 'small';
  const viewBox = typo ? '0 0 563.797 144' : '0 0 144 144';
  const classes = clsx(
    logo,
    styles[logoSize],
    { [styles.center]: center },
    { [styles.typo]: typo },
    { [styles.dark]: dark }
  );

  const theLogo = (
    <svg viewBox={viewBox}>
      <defs>
        <linearGradient
          id="logo-gradient"
          x1="0.713"
          x2="0.695"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#006cff" />
          <stop offset="1" stopColor="#60d3bd" />
        </linearGradient>
        {/* <filter id="logo-shadow" x="0" y="0" width="200%" height="200%">
        <feOffset result="offOut" in="SourceAlpha" dx="20" dy="20" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter> */}
      </defs>
      <path d="M24,0h96a24,24,0,0,1,24,24v96a24,24,0,0,1-24,24H24A24,24,0,0,1,0,120V24A24,24,0,0,1,24,0Z" />
      <g transform="translate(30 27)">
        <ellipse
          cx="29.849"
          cy="12.679"
          rx="29.849"
          ry="12.679"
          transform="translate(12.151 67.623)"
        />
        <path
          className={path}
          d="M314.194,401.01l31.545,54.98a10,10,0,0,0,8.666,5.061h0a10,10,0,0,0,8.666-5.061l31.545-54.98a13.488,13.488,0,0,0-3.461-17.4h0a13.028,13.028,0,0,0-20.059,5.719l-6.143,15.613a11.292,11.292,0,0,1-21.1,0l-6.143-15.613a13.028,13.028,0,0,0-20.059-5.719h0A13.488,13.488,0,0,0,314.194,401.01Z"
          transform="translate(-312.406 -380.94)"
        />
      </g>
      <g transform="translate(-315.886 -795.515)">
        <path
          d="M504.439,901.984,489.886,863.7h9.4l7.628,22.11a34.478,34.478,0,0,1,1.342,4.521,30.934,30.934,0,0,1,.707,4.168h.283a30.394,30.394,0,0,1,.848-4.238q.635-2.4,1.342-4.451l7.63-22.11h9.395L513.9,901.984Z"
          transform="translate(0 -10.323)"
        />
        <path
          d="M580.9,862.421q7.7,0,12.185,4.592t4.485,12.786v4.522H572.282q.139,5.227,2.9,8.088a10.093,10.093,0,0,0,7.629,2.861,27.41,27.41,0,0,0,6.641-.742,35.5,35.5,0,0,0,6.075-2.154v7.134a25.965,25.965,0,0,1-5.863,1.977,35.994,35.994,0,0,1-7.276.636,21.973,21.973,0,0,1-9.96-2.155,15.6,15.6,0,0,1-6.747-6.5,22.081,22.081,0,0,1-2.437-10.914q0-9.819,4.838-14.977T580.9,862.421Zm0,6.57a7.75,7.75,0,0,0-5.828,2.3q-2.226,2.3-2.579,6.817h16.46a10.9,10.9,0,0,0-2.013-6.535Q585,868.991,580.9,868.991Z"
          transform="translate(-32.667 -9.755)"
        />
        <path
          d="M660.8,862.421q6.428,0,10.067,3.321T674.5,876.48v24.936h-8.9v-23.17q0-4.308-1.7-6.464t-5.369-2.155q-5.369,0-7.347,3.391t-1.978,9.678v18.721h-8.9V863.128h6.852l1.271,5.015h.495a10.809,10.809,0,0,1,5.051-4.345A17.112,17.112,0,0,1,660.8,862.421Z"
          transform="translate(-66.989 -9.755)"
        />
        <path
          d="M753.278,863.7v38.288h-6.993l-1.2-4.945h-.423a10.6,10.6,0,0,1-5.087,4.31,17.79,17.79,0,0,1-6.853,1.342q-6.428,0-10.067-3.32T719.017,888.7V863.7h8.9v23.241q0,4.31,1.7,6.428t5.369,2.12q5.369,0,7.383-3.392t2.014-9.678V863.7Z"
          transform="translate(-102.039 -10.323)"
        />
        <path
          d="M812.33,862.421q7.7,0,12.186,4.592T829,879.8v4.522h-25.29q.14,5.227,2.9,8.088a10.091,10.091,0,0,0,7.628,2.861,27.407,27.407,0,0,0,6.641-.742,35.516,35.516,0,0,0,6.076-2.154v7.134a25.979,25.979,0,0,1-5.864,1.977,35.99,35.99,0,0,1-7.276.636,21.974,21.974,0,0,1-9.96-2.155,15.6,15.6,0,0,1-6.747-6.5,22.082,22.082,0,0,1-2.437-10.914q0-9.819,4.838-14.977T812.33,862.421Zm0,6.57a7.75,7.75,0,0,0-5.828,2.3q-2.225,2.3-2.579,6.817h16.46a10.9,10.9,0,0,0-2.013-6.535Q816.426,868.991,812.33,868.991Z"
          transform="translate(-135.73 -9.755)"
        />
        <path
          d="M902.032,877.885a12.821,12.821,0,0,1-4.875,10.6q-4.874,3.886-13.492,3.885a32.006,32.006,0,0,1-14.129-2.825v-8.478a60.353,60.353,0,0,0,7.028,2.579,26.607,26.607,0,0,0,7.736,1.164q4.591,0,6.675-1.8a6.061,6.061,0,0,0-.6-9.607,45,45,0,0,0-7.841-4.062,35.152,35.152,0,0,1-5.97-3.143,15.422,15.422,0,0,1-4.662-4.734,13.226,13.226,0,0,1-1.8-7.17,12.272,12.272,0,0,1,4.557-10.1q4.555-3.673,12.186-3.674a32.455,32.455,0,0,1,7.523.848,39.352,39.352,0,0,1,7.03,2.4l-2.9,7.276a47.994,47.994,0,0,0-6.041-2.119,22.417,22.417,0,0,0-5.9-.778,8.4,8.4,0,0,0-5.581,1.625,5.381,5.381,0,0,0-1.906,4.309,5.761,5.761,0,0,0,1.059,3.5,9.669,9.669,0,0,0,3.285,2.649q2.225,1.2,5.757,2.755a42.255,42.255,0,0,1,6.958,3.709,14,14,0,0,1,4.381,4.593A13.122,13.122,0,0,1,902.032,877.885Z"
          transform="translate(-169.07 0)"
        />
        <path
          d="M955.893,902.122q-8.2,0-12.963-4.732t-4.768-14.906q0-7.062,2.366-11.48a14.887,14.887,0,0,1,6.534-6.5,21.043,21.043,0,0,1,9.536-2.084,26.737,26.737,0,0,1,6.394.707,23.5,23.5,0,0,1,4.838,1.694l-2.614,6.994q-2.261-.849-4.451-1.449a15.83,15.83,0,0,0-4.168-.6q-9.323,0-9.325,12.645,0,6.145,2.367,9.254a8.047,8.047,0,0,0,6.817,3.109,19.191,19.191,0,0,0,5.686-.777,20.641,20.641,0,0,0,4.7-2.119v7.7a16.059,16.059,0,0,1-4.733,1.942A27.537,27.537,0,0,1,955.893,902.122Z"
          transform="translate(-199.631 -9.755)"
        />
        <path
          d="M1034.872,882.2q0,9.538-4.945,14.73t-13.423,5.191a18.514,18.514,0,0,1-9.325-2.331,16.278,16.278,0,0,1-6.428-6.781,23.049,23.049,0,0,1-2.331-10.809q0-9.536,4.909-14.657t13.387-5.122a18.893,18.893,0,0,1,9.4,2.3,16.1,16.1,0,0,1,6.429,6.711A22.835,22.835,0,0,1,1034.872,882.2Zm-27.339,0q0,6.078,2.154,9.4t6.959,3.321q4.732,0,6.923-3.321t2.19-9.4q0-6.073-2.19-9.324t-6.993-3.249q-4.8,0-6.923,3.249T1007.533,882.2Z"
          transform="translate(-226.467 -9.755)"
        />
        <path
          d="M1109.351,863.7v38.288h-6.993l-1.2-4.945h-.423a10.6,10.6,0,0,1-5.087,4.31,17.789,17.789,0,0,1-6.852,1.342q-6.428,0-10.067-3.32T1075.09,888.7V863.7h8.9v23.241q0,4.31,1.7,6.428t5.368,2.12q5.369,0,7.383-3.392t2.014-9.678V863.7Z"
          transform="translate(-260.61 -10.323)"
        />
        <path
          d="M1166.193,888.805a16.274,16.274,0,0,0,3.214-.318,27.674,27.674,0,0,0,2.931-.742v6.641a14.148,14.148,0,0,1-3.674,1.13,25.618,25.618,0,0,1-4.732.423,15.973,15.973,0,0,1-5.969-1.059,8.391,8.391,0,0,1-4.2-3.745,15.185,15.185,0,0,1-1.554-7.558V863.656h-5.157v-3.885l5.651-3.108,2.826-8.124h5.58v8.407h10.879v6.71h-10.879v19.85a5.107,5.107,0,0,0,1.449,3.991A5.215,5.215,0,0,0,1166.193,888.805Z"
          transform="translate(-292.655 -3.573)"
        />
      </g>
    </svg>
  );

  return (
    <div className={classes}>
      {noLink ? (
        theLogo
      ) : (
        <Link href="/">
          <a>{theLogo}</a>
        </Link>
      )}
    </div>
  );
}
