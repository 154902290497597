import React, { useState, useEffect, MouseEvent } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import clsx from 'clsx';
import styles from '../../styles/components/molecules/Navigation.module.scss';

interface NavigationProps {
  className?: string;
  light?: boolean;
  small?: boolean;
  vertical?: boolean;
  minified?: boolean;
  data: {
    path: string;
    label: string;
    icon?: any;
    options?: any;
  }[];
}

export default function Navigation(props: NavigationProps) {
  const { navigation } = styles;
  const { data, small, light, minified, vertical } = props;
  const router = useRouter();
  const currPath = router.asPath;
  const navMatch = (itemPath: string) => {
    switch (itemPath) {
      case '/':
        return currPath === itemPath || currPath.indexOf('search') > -1;
      case '/company':
        return currPath === itemPath;
      default:
        return currPath === itemPath || currPath.indexOf(itemPath) > -1;
    }
  };

  const classes = clsx(
    navigation,
    { [styles.minified]: small && vertical },
    { [styles.minified]: minified },
    { [styles.small]: small },
    { [styles.vertical]: vertical },
    { [styles.light]: light }
  );

  const links = data.map((item, index) => {
    const itemPath = `${item.path}`;
    const active = navMatch(itemPath);
    const classes = clsx(
      navigation,
      { [styles.active]: active },
      { [styles.inbox]: itemPath === '/my-inbox' && !!item.icon?.props.options }
    );
    return (
      <li key={index} className={classes}>
        <Link href={itemPath}>
          <a>
            {small && vertical ? <small>{item.label}</small> : item.label}
            {!!item.icon && item.icon}
          </a>
        </Link>
      </li>
    );
  });

  return (
    <nav className={classes}>
      <ul>{links}</ul>
    </nav>
  );
}
