import React from 'react';
import clsx from 'clsx';
import styles from '../../styles/components/organisms/Offset.module.scss';

interface OffsetProps {
  className?: string;
  children?: any;
  xs?: boolean;
  full?: boolean;
  slide?: boolean;
}

export default function Offset(props: OffsetProps) {
  const { full, slide, xs, className, children } = props;
  const classes = clsx(
    'offset',
    styles.offset,
    { [styles.xs]: xs },
    { [styles.full]: full },
    { [styles.slide]: slide },
    className
  );
  return <div className={classes}>{children}</div>;
}
