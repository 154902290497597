import { countryData } from '../data/countries';
const APIKEY = '4c89eb48800c4e95aea32ada79ee0101';

let controller: AbortController | null;

// TODO rework this to use the new API

export async function geocomplete(
  input: string,
  type?: string,
  limit?: number
) {
  if (controller) {
    controller.abort();
    controller = null;
  }

  controller = new AbortController();
  const typeStr = type ? `&type=${type}` : ''; // country, state, city, postcode, street, amenity
  const maxResults = limit || 5;

  try {
    const response = await fetch(
      `/api/geo/complete?keyword=${input}${typeStr}&limit=${maxResults}`,
      { signal: controller.signal }
    );

    const data = await response.json();

    return data;
  } catch (e: any) {
    if (e.name === 'AbortError') {
      return null;
    }
  }
}

export async function geolocate() {
  if (controller) {
    controller.abort();
    controller = null;
  }

  controller = new AbortController();

  try {
    const response = await fetch(
      `https://api.geoapify.com/v1/ipinfo?apiKey=${APIKEY}`,
      { signal: controller.signal }
    );

    const data = await response.json();
    return data;
  } catch (e: any) {
    if (e.name === 'AbortError') {
      return null;
    }
  }
}

export async function iplocate() {
  // const example = {
  //   country: {
  //     name: 'Brazil',
  //     iso_code: 'BR',
  //     name_native: 'Brasil',
  //     phone_code: '55',
  //     capital: 'Brasília',
  //     currency: 'BRL',
  //     flag: '🇧🇷',
  //     languages: [
  //       {
  //         iso_code: 'pt',
  //         name: 'Portuguese',
  //         name_native: 'Português',
  //       },
  //     ],
  //   },
  //   state: { name: 'Espirito Santo' },
  //   city: { name: 'Serra' },
  //   postcode: '29160',
  //   location: { latitude: -20.1082, longitude: -40.3019 },
  //   continent: { code: 'SA', name: 'South America' },
  //   ip: '2804:14d:9482:82ab:412f:8da1:83b4:2e97',
  // };
  const response = await fetch(
    `https://api.geoapify.com/v1/ipinfo?apiKey=${APIKEY}`
  );
  const data = await response.json();
  const { postcode, city, state, country } = data || {};
  const { iso_code, languages } = country || {};
  const langs = languages.reduce(
    (acc: any, curr: any) => [...acc, curr.iso_code],
    []
  );
  const countryCode = iso_code.toUpperCase();
  const tax_id_type = (countryData[countryCode] || {}).taxType;
  const address = `${postcode} ${city.name}, ${state.name}, ${country.name}`;
  return {
    ...country,
    languages: langs,
    country: country.name,
    state: state.name,
    city: city.name,
    postcode,
    tax_id_type,
    address,
  };
}
