import React, { MouseEvent } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Logo from '../atoms/Logo';
import Navigation from '../molecules/Navigation';
import Meta from '../molecules/Meta';
import styles from '../../styles/components/organisms/Header.module.scss';

interface HeaderProps {
  bg?: boolean;
  dark?: boolean;
  hidden?: boolean;
  logoOnly?: boolean;
  minified?: boolean;
  toggle?: (e?: string) => void;
}

export default function Header(props: HeaderProps) {
  const { t } = useTranslation(['pages']);
  const { bg, dark, hidden, minified, logoOnly, toggle } = props;
  const headerTheme = dark ? 'dark' : 'light';
  const classes = clsx(
    styles.header,
    styles[headerTheme],
    { [styles.bg]: bg },
    { [styles.hidden]: hidden },
    { [styles.minified]: minified }
  );

  const mainMenu = [
    { path: `/`, label: t('pages:home:navi') },
    {
      path: `/what-is-venueScout`,
      label: t('pages:what-is-venueScout:navi'),
    },
    {
      path: `/promote-your-business`,
      label: t(`pages:promote-your-business:navi`),
    },
  ].filter((el) => !!el) as any;

  return (
    <header className={classes}>
      <div>
        <Logo
          dark={logoOnly || !dark}
          medium={!minified}
          typo={logoOnly || !minified}
        />
        {!logoOnly && (
          <Navigation light={dark} minified={minified} data={mainMenu} />
        )}
        {!logoOnly && <Meta toggle={toggle} dark={dark} minified={minified} />}
      </div>
    </header>
  );
}
