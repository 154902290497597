import React, { useState, useEffect, MouseEvent } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { toClass } from '../../lib/utils';
import Icon from './Icon';
import styles from '../../styles/components/atoms/Button.module.scss';

interface ButtonProps {
  className?: string;
  icon?: string;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void | undefined;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void | undefined;
  onMouseOver?: (e: React.MouseEvent<HTMLButtonElement>) => void | undefined;
  onMouseOut?: (e: React.MouseEvent<HTMLButtonElement>) => void | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | undefined;
  onIconClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | undefined;
  label?: string;
  title?: string;
  add?: any;
  href?: string | null;
  fill?: boolean;
  shadow?: boolean;
  full?: boolean;
  half?: boolean;
  tiny?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  toggle?: boolean;
  hover?: boolean;
  dark?: boolean;
  subtl?: boolean;
  type?: 'submit' | 'button' | 'reset';
  float?: boolean;
  active?: boolean;
  text?: boolean;
  center?: boolean;
  iconLarge?: boolean;
  iconLeft?: boolean;
  more?: boolean;
  iconFill?: boolean;
  white?: boolean;
  gold?: boolean;
  red?: boolean;
  search?: boolean;
  border?: boolean;
  round?: boolean;
  colored?: boolean;
  transparent?: boolean;
  blocked?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  input?: boolean;
  loading?: boolean;
}

export default function Button(props: ButtonProps) {
  const {
    label,
    add,
    href,
    fill,
    full,
    half,
    tiny,
    small,
    medium,
    large,
    dark,
    red,
    subtl,
    colored,
    transparent,
    search,
    white,
    gold,
    more,
    toggle,
    hover,
    float,
    active,
    icon,
    title,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onMouseOver,
    onMouseOut,
    onIconClick,
    text,
    center,
    iconLeft,
    iconLarge,
    iconFill,
    shadow,
    blocked,
    type = 'button',
    disabled,
    border,
    round,
    className,
    children,
    input,
    loading,
  } = props;

  const router = useRouter();
  const [isActive, setActive] = useState(false);
  const handleMouseEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    switch (e.type) {
      case 'mouseenter':
        !!onMouseEnter && onMouseEnter(e);
        break;
      case 'mouseleave':
        !!onMouseLeave && onMouseLeave(e);
        break;
      case 'mouseover':
        !!onMouseOver && onMouseOver(e);
        break;
      case 'mouseout':
        !!onMouseOut && onMouseOut(e);
        break;
      case 'click':
      default:
        if (href && href.indexOf('://') > -1) {
          window.open(href, '_blank', 'noopener noreferrer');
        } else if (href) {
          router.push(href);
        }
        if (toggle) {
          setActive(!isActive);
        }
        if (!!onClick) {
          e.preventDefault();
          onClick(e);
        }
        break;
    }
  };

  const { btn } = styles;
  const btnType = toggle
    ? 'toggle'
    : large
    ? 'large'
    : medium
    ? 'medium'
    : small
    ? 'small'
    : tiny
    ? 'tiny'
    : '';
  const btnSize = full ? 'full' : half ? 'half' : 'auto';
  const btnIcon =
    (toggle && !icon) || more || icon === 'none'
      ? false
      : icon || (iconLeft ? 'arrow-l' : 'arrow-r');
  const btnName = toClass(label || btnIcon);
  const coloredHover =
    dark ||
    ((colored || large || medium) &&
      !toggle &&
      !search &&
      !gold &&
      !(white && full) &&
      !text &&
      label);
  const classes = clsx(
    btn,
    'btn',
    btnName,
    { [styles[btnType]]: btnType },
    styles[btnSize],
    { [styles.icOnly]: !!!label },
    { [styles.float]: float },
    { [styles.text]: text },
    { [styles.switch]: text },
    { [styles.transparent]: transparent },
    { [styles.colored]: coloredHover },
    { [styles.dark]: dark },
    { [styles.white]: white },
    { [styles.subtl]: subtl },
    { [styles.gold]: gold },
    { [styles.red]: red },
    { [styles.more]: more },
    { [styles.center]: center },
    { [styles.iconLeft]: iconLeft || btnIcon === 'arrow-l' },
    { [styles.iconFill]: iconFill },
    { [styles.iconLarge]: iconLarge },
    { [styles.hover]: hover },
    { [styles.inactive]: !!!onClick && !!!href },
    { [styles.blocked]: blocked },
    { [styles.active]: isActive },
    { [styles.fill]: fill },
    { [styles.iconFill]: iconFill },
    { [styles.border]: border },
    { [styles.round]: round },
    { [styles.shadow]: shadow },
    { [styles.loading]: loading },

    className
  );

  useEffect(() => {
    setActive(!!active);
  }, [active]);

  return (
    <button
      disabled={disabled}
      className={classes}
      title={title}
      type={type}
      onClick={handleMouseEvent}
      onMouseEnter={handleMouseEvent}
      onMouseLeave={handleMouseEvent}
      onMouseOver={handleMouseEvent}
      onMouseOut={handleMouseEvent}
    >
      <div>
        {!!label && <span>{!input ? label : ''}</span>}
        {!!btnIcon && <Icon name={btnIcon} onClick={onIconClick} />}
        {!!add && <span>{add}</span>}
        {children}
      </div>
    </button>
  );
}
