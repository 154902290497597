import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Language from '../molecules/Language';
import Metrics from '../molecules/Metrics';
import Button from '../atoms/Button';
import { useAuth } from '../../context/AuthUserContext';
import { useApp } from '../../context/AppContext';
import styles from '../../styles/components/molecules/Meta.module.scss';

interface MetaProps {
  openCanvas?: boolean;
  dark?: boolean;
  hidden?: boolean;
  logoOnly?: boolean;
  minified?: boolean;
  toggle?: (el?: string) => void;
}

export default function Meta(props: MetaProps) {
  const { user, rfpCount } = useAuth();
  const { t } = useTranslation(['common']);
  const { openCanvas, dark, minified, toggle } = props;
  const metaTheme = dark ? 'dark' : 'light';
  const classes = clsx(
    styles.meta,
    styles[metaTheme],
    { [styles.logged]: !!user },
    { [styles.inCanvas]: openCanvas },
    { [styles.minified]: minified }
  );

  return (
    <ul className={classes}>
      {/* {!!user && (
        <li>
          <Button
            className={styles.help}
            icon={'circle'}
            href={'mailto:feedback@venuescout.org'}
            title={t('common:get-help')}
          />
        </li>
      )} */}
      <li>
        <Metrics className={styles.metrics} dark={openCanvas || !dark} />
      </li>

      {/*<li>
        <Language />
      </li>*/}

      {
        <li className={styles.search}>
          <Button icon="search" onClick={() => toggle && toggle('search')} />
        </li>
      }
      <li
        className={!!rfpCount ? styles.count : undefined}
        data-count={rfpCount?.toString()}
      >
        <Button
          icon={openCanvas ? 'close' : 'account-big'}
          onClick={() => toggle && toggle('canvas')}
        />
      </li>
    </ul>
  );
}
